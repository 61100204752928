export const hydrateTask = (root, taskCache) => {
  taskCache = taskCache || {}
  root.commands = root.commands || [];
  root.full_command = root.commands.join('\n');
  root.tags = root.tags || []
  root.input_params = root.input_params || []
  root.output_params = root.output_params || []
  root.parent_tasks = root.parent_tasks || []
  root.collaborators = root.collaborators || {}
  root.contributors = root.contributors || {}
  root.voters = root.voters || {}
  root.num_parents = root.parent_tasks.length
  root.num_voters = Object.keys(root.voters).length
  root.num_collaborators = Object.keys(root.collaborators).length
  root.num_contributors = Object.keys(root.contributors).length
  if (!root.sub_tasks) root.sub_tasks = []
  for (var i = 0;i < root.sub_tasks.length;i++) {
    const subtask = root.sub_tasks[i]
    if (!subtask.task) {
      subtask.task = taskCache[subtask.taskid]
      subtask.inputs = subtask.inputs || {}
      subtask.outputs = subtask.outputs || {}
    }
  }
}
